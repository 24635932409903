<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<style lang="scss" scoped>
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>

<script>
import { SET_MENU } from '@/core/services/store/modules/menu.module';
import localData from '../../../utils/saveDataToLocal';
export default {
  components: {},
  mounted() {
    this.$store.dispatch(SET_MENU, [
      {
        name: 'Sản phẩm',
        icon: 'menu-icon flaticon-tool',
        route: '/products',
        show: this.checkPermission('PRODUCT_VIEW'),
      },
      {
        name: 'Danh mục sản phẩm',
        icon: 'menu-icon flaticon2-menu-1',
        route: '/categories',
        show: this.checkPermission('CATE_VIEW'),
      },
      {
        name: 'Thuộc tính sản phẩm',
        icon: 'menu-icon flaticon2-tools-and-utensils',
        route: '/properties',
        show: this.checkPermission('PROPERTY_VIEW'),
      },
      {
        name: 'Thương hiệu',
        icon: 'menu-icon flaticon-bell-1',
        route: '/brands',
        show: this.checkPermission('BRAND_VIEW'),
      },
      {
        name: 'Nhà cung cấp',
        icon: 'menu-icon flaticon2-delivery-truck',
        route: '/providers',
        show: this.checkPermission('PROVIDER_VIEW'),
      },
      {
        name: 'Danh sách IMEI',
        icon: 'menu-icon flaticon2-delivery-truck',
        route: '/imeis',
        show: this.checkPermission('PRODUCT_VIEW'),
      },
      {
        name: 'Lịch sử IMEI',
        icon: 'menu-icon flaticon2-delivery-truck',
        route: '/imei-histories',
        show: this.checkPermission('IMEI_HISTORY_VIEW'),
      },
      {
        name: 'Bộ phụ kiện tặng kèm',
        icon: 'menu-icon flaticon2-gift',
        route: '/accessories',
        show: true,
      },
      {
        name: 'Bộ phụ kiện khuyến mãi',
        icon: 'menu-icon flaticon2-gift',
        route: '/promotion-accessories',
        show: true,
      },
      {
        name: 'Gói bảo hành',
        icon: 'menu-icon fas fa-tools',
        route: '/warranty-packages',
        show: true
      }
    ]);
  },
  methods: {
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
